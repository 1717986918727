
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































































$categories-height: 9.6rem;
$bp: m;

.magazine-personality {
  margin-top: calc(var(--header-height-down) + #{$categories-height});
}

.magazine-personality__hero {
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  @include mq($bp) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

.magazine-personality__hero__content {
  position: relative;
  z-index: 1;
  margin-top: $spacing * -2;

  @include mq($bp) {
    flex: 1;
    margin: 0 0 0 $spacing;
  }

  @include mq(xl) {
    flex: col(5, 10) 0 0;
  }
}

.magazine-personality__hero__picture {
  position: relative;
  width: 120%;
  height: auto;
  margin-left: col(-1, 10);
  padding-bottom: 140%;
  background: none;

  img {
    @include image-fit(cover, top);
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 20%;
    background: linear-gradient(to top, $c-white, rgba($c-white, 0%));
  }

  @include mq($bp) {
    width: col(4, 10);
    height: fit-content;
    margin-left: 0;
    padding: 0;

    img {
      position: static;
    }

    &::after {
      content: none;
    }
  }
}

.magazine-personality__gallery {
  @include mq($bp) {
    margin-top: $spacing * 6;
  }
}

.magazine-personality__flexible-component {
  // override default HO episodes styles
  &:not(:first-child) {
    @include default-margin(0.6);
  }
}
